<template>
  <div v-if="clientSiteData && $can('update', 'clients')">
    <!-- Breadcrumb -->
    <b-row
      class="content-header"
    >
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Client Account
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/apps/cameras/list">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  to="/apps/clients/list"
                >
                  Client Account
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  :to="'/apps/clients/view/'+clientSiteData.clientname"
                >
                  {{ clientSiteData.clientname }}
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  active
                >
                  Edit Site: {{ clientSiteData.sitename }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <component :is="clientSiteData === undefined ? 'div' : 'b-card'">

      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="clientSiteData === undefined"
      >
        <h4 class="alert-heading">
          Error fetching client site data
        </h4>
        <div class="alert-body">
          No client sites found with this client name and site name.
        </div>
      </b-alert>

      <client-sites-edit-tab-account
        :client-site-data="clientSiteData"
        class="mt-2 pt-75"
      />
    </component>
  </div>
</template>

<script>
import {
  BRow, BCol, BTab, BTabs, BCard, BAlert, BLink, BBreadcrumb, BBreadcrumbItem,
} from 'bootstrap-vue'

import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import clientStoreModule from '../clientStoreModule'
import ClientSitesEditTabAccount from './ClientSitesEditTabAccount.vue'
// import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BBreadcrumb,
    BBreadcrumbItem,

    ClientSitesEditTabAccount,
  },
  setup() {
    const clientSiteData = ref(null)

    const STORE_MODULE_NAME = 'app-client'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    console.log(router.currentRoute.params.clientname)
    console.log(router.currentRoute.params.clientSiteData)
    clientSiteData.value = router.currentRoute.params.clientSiteData
    // const qvars = {}
    // qvars.clientname = router.currentRoute.params.clientname
    // qvars.sitename = router.currentRoute.params.sitename
    // if (ability.can('read', 'clients')) {
    //   store.dispatch('app-client/fetchClient', qvars.clientname)
    //     .then(response1 => {
    //       const clientData = response1.data
    //       if (typeof clientData.services === 'undefined') {
    //         clientData.services = {
    //           guard: false,
    //           eyetrax: false,
    //           guardtour: false,
    //           tenant: false,
    //           privateinvestigation: false,
    //         }
    //       }
    //       store.dispatch('app-client/fetchClientSite', qvars)
    //         .then(response => {
    //           clientSiteData.value = response.data
    //           if (typeof clientSiteData.value.tenant === 'undefined') {
    //             clientSiteData.value.tenant = [{ url: '', password: '', max_permits: '' }]
    //           }
    //           if (typeof clientSiteData.value.geofence === 'undefined') {
    //             clientSiteData.value.geofence = [{ lat: '', lon: '', distance: '' }]
    //           }
    //           clientSiteData.value.clientServices = clientData.services
    //         })
    //         .catch(error => {
    //           console.log(error)
    //           if (typeof error.response !== 'undefined' && error.response.status === 404) {
    //             clientSiteData.value = undefined
    //           }
    //         })
    //     })
    //     .catch(error => {
    //       console.log(error)
    //       if (typeof error.response !== 'undefined' && error.response.status === 404) {
    //         clientSiteData.value = undefined
    //       }
    //     })
    // }

    return {
      clientSiteData,
    }
  },
}
</script>

<style>

</style>
